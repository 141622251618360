
    @use 'src/scss/app';

    .post:first-of-type {
        overflow: auto;
    }

    h2 {
        text-align: center;
    }

    .buttons {
        display: flex;
        gap: app.$gutter;
        max-width: max-content;
        margin: app.$gutter * 2 auto 0 auto;
    }
