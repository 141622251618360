
    @use 'src/scss/app';

    .code-module {
        padding: 0 app.$module-xs-padding;
        margin: app.$module-xs-margin 0;
    }

    pre {
        margin: 0;
    }

    @media (min-width: app.$screen-width-sm-min) {
        .code-module {
            padding: 0 app.$module-sm-padding;
            margin: app.$module-sm-margin 0;
        }
    }

    @media (min-width: app.$screen-width-md-min) {
        .code-module {
            padding: 0 app.$module-md-padding;
            margin: app.$module-md-margin 0;
        }
    }

    @media (min-width: app.$screen-width-lg-min) {
        .code-module {
            padding: 0 app.$module-lg-padding;
        }
    }
