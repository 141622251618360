
    @use 'src/scss/app';

    h1 {
        text-align: center;
    }

    .categories {
        display: block;
        max-width: max-content;
        margin: app.$gutter * 2 auto 0 auto;
    }
