
    @use 'src/scss/app';

    .pagination {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: app.$gutter;
        width: fit-content;
        margin: app.$gutter * 2 auto 0 auto;
    }
