
    /********
     * HEADER
     ********/

    @use 'src/scss/app';

    header {
        height: 9.0rem;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .avatar {
            display: block;
            margin: 1.0rem;
            width: 7.0rem;
            min-width: 7.0rem;
            height: 7.0rem;

            img {
                width: 100%;
            }
        }

        h1 {
            font-size: 1.8rem;
            font-weight: 500;
            margin: 0;
            font-style: italic;

            strong {
                font-weight: 900;
            }

            a {
                &:link, &:visited, &:hover, &:active {
                    color: app.$color-body-text;
                    text-decoration: none;
                }
            }
        }

        .social-connections {
            margin-left: auto;
            display: grid;
            grid-template-columns: 1fr 1fr;
            margin-right: 1.0rem;
            gap: 0.5rem;

            a {
                display: block;
            }

            img {
                width: 3.0rem;
                height: 3.0rem;
                vertical-align: middle;
            }
        }
    }

    @media (min-width: app.$screen-width-sm-min) {
        header {
            height: 9.0rem;

            h1 {
                font-size: 2.1rem;
                margin-left: app.$gutter;
            }

            .social-connections {
                grid-template-columns: 1fr 1fr 1fr 1fr;
                margin-right: 2.0rem;
                gap: 1rem;

                img {
                    width: 5.0rem;
                    height: 5.0rem;
                }
            }
        }
    }

    /********
     * FOOTER
     ********/

    footer {
        text-align: center;
        padding: app.$gutter * 2 0;

        span {
            opacity: .5;
        }
    }
