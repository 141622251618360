
    @use 'src/scss/app';

    h1 {
        text-align: center;
    }

    nav {
        display: grid;
        grid-template-columns: 1fr;
        max-width: 1280px;
        margin: 0 auto;
        gap: app.$gutter;

        &>div {
            background-color: rgba(app.$color-black, 0.3);
            padding: 0 app.$gutter * 2 app.$gutter * 2 app.$gutter * 2;

            img {
                max-width: calc(100% + #{app.$gutter * 4});
                margin: 0 #{- app.$gutter * 2};
            }
        }
    }

    @media (min-width: app.$screen-width-sm-min) {
        nav {
            grid-template-columns: 1fr 1fr;
        }
    }

    @media (min-width: app.$screen-width-md-min) {
        nav {
            grid-template-columns: 1fr 1fr 1fr;
        }
    }
