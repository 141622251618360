
    @use 'src/scss/app';

    .post {
        margin: 0 auto 10px auto;
        background-color: rgba(app.$color-black, 0.3);
        max-width: 1280px;
        padding-top: 1px;
    }

    .post-meta {
        height: 30px;
        background-color: app.$color-primary;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }

    .post-icon {
        background-color: app.$color-white;
        height: 100%;
        color: app.$color-grey-dark;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 5px 0 10px;
        position: relative;

        &>div {
            width: 0;
            height: 0;
            border-top: 15px solid transparent;
            border-bottom: 15px solid transparent;
            border-left: 15px solid app.$color-white;
            position: absolute;
            top: 0;
            right: -15px;
        }
    }

    .post-title {
        padding-left: 25px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        a {
            &:link, &:visited, &:hover, &:active {
                color: app.$color-white;
                text-decoration: none;
            }
        }
    }
