
    @use 'src/scss/app';

    .post-header {
        margin: 0 auto app.$gutter auto;
        background-color: app.$color-grey-lighter;
        max-width: 1280px;
        display: flex;
        flex-wrap: wrap;

        .segment {
            display: flex;
            flex-grow: 1;
            align-items: flex-start;
            background-color: app.$color-white;
            position: relative;
            padding: app.math-div(app.$gutter, 2.5) app.$post-header-base;

            &:link, &:visited, &:hover, &:active {
                color: app.$color-grey-dark;
                text-decoration: none;
            }

            &:not(:last-child) {
                padding-right: app.$post-header-base * 2;
            }

            .arrow-left {
                display: block;
                width: 0;
                height: 0;
                border-top: app.$post-header-base solid transparent;
                border-bottom: app.$post-header-base solid transparent;
                border-right: app.$post-header-base solid app.$color-white;
                position: absolute;
                top: 0;
                left: - app.$post-header-base;
            }

            svg:not(:last-child){
                margin-right: app.math-div(app.$post-header-base, 3);
            }

            &.back-home {
                flex-grow: 0;
            }

            &.category {
                background-color: app.$color-grey-lighter;
                flex-grow: 0;

                .arrow-left {
                    border-right-color: app.$color-grey-lighter;
                }
            }

            &.title {
                background-color: app.$color-primary;
                color: app.$color-white;

                .arrow-left {
                    border-right-color: app.$color-primary;
                }
            }
        }


    }
