/*************************************************
 * LAYOUT
 *************************************************/

$gutter: 1.5rem;
$post-header-base: 1.5rem;
$module-xs-padding: 1.5rem;
$module-sm-padding: 3.0rem;
$module-md-padding: 4.0rem;
$module-lg-padding: 8.0rem;
$module-xs-margin: 1.5rem;
$module-sm-margin: 3.0rem;
$module-md-margin: 4.0rem;
$module-lg-margin: 8.0rem;
