/*************************************************
 * BREAKPOINTS
 *************************************************/

$screen-width-sm-min: 576px;
$screen-width-md-min: 768px;
$screen-width-lg-min: 992px;
$screen-width-xl-min: 1200px;

$screen-width-xs-max: 575.99px;
$screen-width-sm-max: 767.99px;
$screen-width-md-max: 991.99px;
$screen-width-lg-max: 1199.99px;
