
    @use 'src/scss/app';

    .post-entry-page .read-more-module {
        display: none;
    }

    .read-more-module {
        padding: 0 app.$module-xs-padding;
        margin: app.$module-xs-margin 0;
    }

    @media (min-width: app.$screen-width-sm-min) {
        .read-more-module {
            padding: 0 app.$module-sm-padding;
            margin: app.$module-sm-margin 0;
        }
    }

    @media (min-width: app.$screen-width-md-min) {
        .read-more-module {
            padding: 0 app.$module-md-padding;
            margin: app.$module-md-margin 0;
        }
    }

    @media (min-width: app.$screen-width-lg-min) {
        .read-more-module {
            padding: 0 app.$module-lg-padding;
        }
    }
