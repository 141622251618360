
    @use 'src/scss/app';

    .text-module {
        &> div > *:first-child {
            margin-top: 0;
        }

        &> div > *:last-child {
            margin-bottom: 0;
        }

        ol, ul {
            li {
                line-height: app.$content-line-height;
                margin-bottom: app.math-div(app.$gutter, 2);
            }
        }
    }
