/*************************************************
 * COLORS
 *************************************************/

$color-primary: #e74c39;

$color-white: #ffffff;
$color-black: #000000;

$color-body-text: #eeeeee;

$color-grey-darker: #111111;
$color-grey-dark: #333333;
$color-grey-medium: #444444;
$color-grey-medium-light: #666666;
$color-grey: #9b9b9b;
$color-grey-light: #d9dde1;
$color-grey-lighter: #eeeff0;

$color-site-background: #222222;
