
    @use 'src/scss/app';

    .image-module {
        padding: 0 app.$gutter;
        margin: app.$gutter 0;

        img {
            max-width: 100%;
            vertical-align: middle;
        }
    }
