
    @use 'src/scss/app';

    h1 {
        text-align: center;
    }

    .no-posts {
        margin: 0 auto;
        padding: 0 app.$module-xs-padding;
        overflow: auto;
        background-color: rgba(app.$color-black, 0.3);
        max-width: 1280px;
        box-sizing: border-box;

        p {
            margin-bottom: app.$gutter * 2;
        }
    }

    .other-categories {
        display: block;
        max-width: max-content;
        margin: app.$gutter * 2 auto 0 auto;
    }

    @media (min-width: app.$screen-width-sm-min) {
        .no-posts {
            padding: 0 app.$module-sm-padding;
        }
    }

    @media (min-width: app.$screen-width-md-min) {
        .no-posts {
            padding: 0 app.$module-md-padding;
        }
    }

    @media (min-width: app.$screen-width-lg-min) {
        .no-posts {
            padding: 0 app.$module-lg-padding;
        }
    }
