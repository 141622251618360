@forward 'sass:math' as math-*;
@forward './abstracts';

@use './abstracts' as app;

:export {
    screenWidthSMMin: app.$screen-width-sm-min;
    screenWidthMDMin: app.$screen-width-md-min;
    screenWidthLGMin: app.$screen-width-lg-min;
    screenWidthXLMin: app.$screen-width-sm-min;
    screenWidthXSMax: app.$screen-width-xs-max;
    screenWidthSMMax: app.$screen-width-sm-max;
    screenWidthMDMax: app.$screen-width-md-max;
    screenWidthLGMax: app.$screen-width-lg-max;

    colorPrimary: app.$color-primary;
    colorWhite: app.$color-white;
    colorBlack: app.$color-black;
    colorGreyDarker: app.$color-grey-darker;
    colorGreyDark: app.$color-grey-dark;
    colorGreyMedium: app.$color-grey-medium;
    colorGreyMediumLight: app.$color-grey-medium-light;
    colorGrey: app.$color-grey;
    colorGreyLight: app.$color-grey-light;
    colorGreyLighter: app.$color-grey-lighter;
    colorSiteBackground: app.$color-site-background;

    contentFontSize: app.$content-font-size;
    contentLineHeight: app.$content-line-height;

    gutter: app.$gutter;
}
